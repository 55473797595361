<template>
  <div class="row mt-4">
    <div class="col-12">
      <material-input id="title" v-model="titleHandler" is-required variant="static" label="Titel"
        placeholder="Vul een titel in" />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <rich-text-editor id="description" v-model:content="descriptionHandler" title="Omschrijving" />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <material-input id="url" v-model="urlHandler" variant="static" label="Website" placeholder="Vul een website in"
        type="url" />
    </div>
  </div>

  <allow-reactions v-model="areReactionsDisabledHandler" />

  <material-button v-if="showUpdate" class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm">
    Update gegevens
  </material-button>
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';

import AllowReactions from '@/components/Shared/AllowReactions/AllowReactions.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import RichTextEditor from '@/components/UI/RichTextEditor.vue';

export default {
  name: 'BasicInfo',

  components: {
    AllowReactions,
    MaterialButton,
    MaterialInput,
    RichTextEditor
  },

  props: {
    description: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    areReactionsDisabled: {
      type: Boolean
    }
  },

  emits: [
    'update:description',
    'update:title',
    'update:url',
    'update:areReactionsDisabled',
  ],

  computed: {
    ...createVModelHandlers(
      'description',
      'title',
      'url',
      'areReactionsDisabled',
    ),
  },
};
</script>